import React from "react";
import { Link } from "react-router-dom";
import "../Styles/contact.css";

const Contact = () => {
  return (
    <div id="contact" className="contact-container">
      <div className="contact-heading">
        <h2>Contact</h2>
      </div>
      <ul className="contact-list">
        <li>
          <Link to="mailto:manoz.kc22@gmail.com">
            <i className="fas fa-envelope"></i>
            <span>Email</span>
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.linkedin.com/in/kc-manoj"
          >
            <i className="fab fa-linkedin"></i>
            <span>LinkedIn</span>
          </Link>
        </li>
        <li>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://github.com/kcmanoz"
          >
            <i className="fab fa-github"></i>
            <span>GitHub</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Contact;
