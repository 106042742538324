import React from "react";
import "../Styles/about.css";

const About = () => {
  return (
    <div id="about" className="about-container ">
      <div className="about-heading">
        <h2>About Me</h2>
      </div>
      <div className="about-text">
        <p>
          I am an enthusiastic frontend developer skilled in creating elegant,
          responsive websites and web applications. With a strong foundation in
          technologies suchas JavaScript, TypeScript, React, HTML, CSS and Jest,
          I continuously strive to stay updated with the latest industry trends
          and emerging technologies. My goal is to deliver high-quality,
          user-centric solutions that meet both client and user needs. I am
          committed to lifelong learning and actively seek opportunities to
          expand my skills and knowledge in web development.
        </p>
      </div>
    </div>
  );
};

export default About;
