import React from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

const App = () => {

  return (
    <Router>
      <Navbar />
      <Home />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </Router>
  )
}

export default App