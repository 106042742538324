import React from "react";
import { Link } from "react-router-dom";
import "../Styles/home.css";
import myImage from "../Images/myImage.png";
import jsIcon from "../Images/Icons/javascript-icon.svg";
import tsIcon from "../Images/Icons/typescript-icon.svg";
import reactIcon from "../Images/Icons/react-js-icon.svg";
import htmlIcon from "../Images/Icons/html-icon.svg";
import cssIcon from "../Images/Icons/css-icon.svg";
import tailwindIcon from "../Images/Icons/tailwind-icon.svg";
import jestIcon from "../Images/Icons/jest-icon.svg";
import gitIcon from "../Images/Icons/git-icon.svg";

const Home = () => {
  return (
    <div id="home" className="home-container">
      <div className="home-content">
        <div className="main">
          <h1>Hi, I'm Manoj KC</h1>
          <h4>a Frontend Developer</h4>
          <span>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://www.linkedin.com/in/kc-manoj"
            >
              <i className="fab fa-linkedin"></i>
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz"
            >
              <i className="fab fa-github"></i>
            </Link>

            <Link to="mailto:manoz.kc22@gmail.com">
              <i className="fas fa-envelope"></i>
            </Link>
          </span>
        </div>
        <div className="image-container">
          <img src={myImage} alt="my pic" className="my-image" />
        </div>
      </div>
      <div className="skills-container">
        <p>Tech Skills</p>
        <ul className="skills-list">
          <li className="skill">
            <img src={jsIcon} alt="javaScript icon" />
          </li>
          <li className="skill">
            <img src={tsIcon} alt="typeScript icon" />
          </li>
          <li className="skill">
            <img src={reactIcon} alt="react icon" />
          </li>
          <li className="skill">
            <img src={htmlIcon} alt="html icon" />
          </li>
          <li className="skill">
            <img src={cssIcon} alt="css icon" />
          </li>
          <li className="skill">
            <img src={tailwindIcon} alt="tailwind icon" />
          </li>
          <li className="skill">
            <img src={jestIcon} alt="jest icon" />
          </li>
          <li className="skill">
            <img src={gitIcon} alt="git icon" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
