import { Link } from "react-scroll";
import React, { useState } from "react";
import "../Styles/navbar.css";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);

    // Toggle dark mode by changing CSS variables
    document.documentElement.style.setProperty(
      "--background-color",
      isDarkMode ? "#fbfcfc" : "#1e3050"
    );

    document.documentElement.style.setProperty(
      "--text-color",
      isDarkMode ? "#333" : "white"
    );
  };

  return (
    <nav id="nav" className="navbar">
      <Link to="home" className="nav-name">
        Manoj KC
      </Link>
      <ul
        onClick={toggleNav}
        className={`nav-list ${isActive ? "active" : ""}`}
      >
        <li className="nav-item">
          <Link to="home" className="nav-link" onClick={toggleNav}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="about" className="nav-link" onClick={toggleNav}>
            About Me
          </Link>
        </li>
        <li className="nav-item">
          <Link to="project" className="nav-link" onClick={toggleNav}>
            Projects
          </Link>
        </li>
        <li className="nav-item">
          <Link to="contact" className="nav-link" onClick={toggleNav}>
            Contact
          </Link>
        </li>
      </ul>
      <span onClick={toggleDarkMode} className="mode-button">
        {isDarkMode ? (
          <i className="far fa-sun"></i>
        ) : (
          <i className="fas fa-moon"></i>
        )}
      </span>
      <div
        onClick={toggleNav}
        className={`hamburger-menu ${isActive ? "active-menu" : ""}`}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};

export default Navbar;
