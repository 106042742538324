import React from "react";
import { Link } from "react-router-dom";
import "../Styles/footer.css";

const Footer = () => {
  return (
    <footer id="footer" className="footer-section">
      <div className="footer-container">
        <p className="footer-text">© 2023 Manoj KC. All rights reserved.</p>
        <div className="footer-icons">
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://www.linkedin.com/in/kc-manoj"
            className="footer-icon"
          >
            <i className="fab fa-linkedin"></i>
          </Link>
          <Link
            target="_blank"
            rel="noreferrer"
            to="https://github.com/kcmanoz"
            className="footer-icon"
          >
            <i className="fab fa-github"></i>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
